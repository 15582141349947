/* eslint-disable array-bracket-newline */
import { InputTextHandler } from 'components/input-text-handler';
import { InputTextHandlerType } from 'components/input-text-handler/InputTextHandler';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import { setUserId } from '@amplitude/analytics-browser';
import track from 'utils/amplitudeWrapper';
import {
  StyledModalView,
  StyledParagraph,
  StyledButtonRow,
  StyledButton,
  StyledAnchor,
  StyledSpanText,
  StyledCheckboxContainer,
  StyledMainContainer,
  ColButtonStyled,
} from './GetRecommendationModal.styles';

import PhoneNumberWrapper from '../../containers/PhoneNumberWrapper';
import { useAppState } from 'context/AppState';
import { OptionType } from 'context/store/flowReducer';
import ProxyLayer from 'base/api/ProxyLayer';
import { useFlowActions } from 'context/actions/flowActions';
import { EButtonType } from 'components/Buttons/ButtonBase';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { CropConstants } from 'utils/constants/Crop';
import { UserCategories } from 'base/types/AmplitudeClassifiers';
import { getCountryPostalCode, TARGET_COUNTRIES_CODES } from '../../utils/helpers/geospatial';
import { getUrl } from 'utils/constants/TermsAndConditionsPrivacyPolicyURLConstants';
import { Country } from 'base/types/Countries';
import { getTraitLabel } from 'utils/trait';
import {
  retrieveBoundariesData,
  retrievePinsData,
  retrieveRecommendationInputData,
} from 'utils/getRecommendationInputData';
import { useBreakpoint } from 'hooks';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { sendConversionEventToGA } from 'components/HeadSection';
import { SMS_ENABLED_COUNTRIES } from 'utils/constants/CountryData';

interface GetRecommendationModalProps {
  title?: string;
  country?: string;
  description: string;
  onCancelClick: Function;
  onGetRecommendationClick: Function;
  isDropAPinFlow?: boolean;
}

const GetRecommendationModal: React.FC<GetRecommendationModalProps> = ({
  onCancelClick,
  onGetRecommendationClick,
  title,
  description,
  isDropAPinFlow,
}) => {
  const [t, i18n] = useTranslation();
  const {
    recommendationForm: {
      countryCode,
      countryRegion,
      agronomicInputs,
      agronomicWeightings,
      pin,
      fields,
      rankingCrop,
      sfdcId,
      selectedCrop,
      storedUserContact,
    },
    flow: { optionType, isLongTermMobileDesign, isModalToBeClosed },
    apiData: { tppData, downyMildew, countryList, fieldsAttributes, attributes },
  } = useAppState();

  const {
    setShowSpinner,
    setEmailRecommendationSent,
    setJourneyCompleted,
    setEmailUpdatedList,
    setShowPrompt,
    setShowUpdateInfoMessage,
    setIsModalToBeClosed,
  } = useFlowActions();
  const { setRecommendationId, setStoredUserContact } = useRecommendationFormActions();
  const [hasInputErrors, setHasInputErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    lastName: false,
  });
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [email, setEmail] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [agreeReceiveNewsUpdates, setAgreeReceiveNewsUpdates] = useState(false);
  const [agreeReceiveRecommendationOnSMS, setAgreeReceiveRecommendationOnSMS] = useState(false);
  const isUserEditingForm = useRef(false);
  const submittingRecommendation = useRef(false);
  const postalCode: string = getCountryPostalCode(countryCode);
  const { isMobile } = useBreakpoint();
  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );
  const selectedCountry = countryList?.find((item: Country) => item.shortCode === countryCode);
  const countryName: string = selectedCountry?.name ?? '';
  const skipContactDetailsForm = Boolean(storedUserContact);
  const isSmsEnabledCountry = countryCode
    ? SMS_ENABLED_COUNTRIES.includes(countryCode?.toUpperCase() || '')
    : true;
  useEffect(() => {
    if (skipContactDetailsForm) {
      return;
    }
    track('display personal info pop up', {
      'map help center interaction': true,
    });
  }, [skipContactDetailsForm]);

  useEffect(() => {
    if (isUserEditingForm.current || skipContactDetailsForm) {
      return;
    }

    if (
      name.length ||
      lastName.length ||
      phoneNumber?.length ||
      email.length ||
      isTermsChecked ||
      agreeReceiveNewsUpdates ||
      agreeReceiveRecommendationOnSMS
    ) {
      isUserEditingForm.current = true;
      track('User personal info filling', {
        'start filling personal info': true,
      });
    }
  }, [
    agreeReceiveNewsUpdates,
    email.length,
    isTermsChecked,
    lastName.length,
    name.length,
    phoneNumber?.length,
    skipContactDetailsForm,
    agreeReceiveRecommendationOnSMS,
  ]);

  const trackAmplitudeEvents = (body: any) => {
    // Track sunflower related amplitude events.
    const trackSunflowerInputs = () => {
      if (body?.recommendationInput?.cropId !== CropConstants.SUNFLOWER_CROP_ID) {
        return;
      }

      const extraParameters = body.recommendationInput?.extraParameters;
      if (Object.keys(body?.recommendationInput?.extraParameters).length === 0) {
        return;
      }

      track('intensity for sunflower', { 'sunflower intensity': extraParameters.rotation });
      track('tillage practice', { tillage: extraParameters.tillage });

      const herbicideTechnologyNames = extraParameters.traits
        .map((tName: string) => getTraitLabel(tName))
        .filter((tName: string) => tName);
      if (herbicideTechnologyNames.length > 0) {
        track('herbicide technology', { herbicide: herbicideTechnologyNames.join(', ') });
      }
      track('broomrape infestation', { broomRape: extraParameters.broomRape.length > 0 });
      if (extraParameters.broomRape.length > 0) {
        track('broomrape resistance technology', {
          'broomrape resistance': extraParameters.broomRape.join(', '),
        });
      }
      track('crop segment', { 'crop segment selected': extraParameters.cropSegments.join(', ') });
    };
    const trackAgronomicWeightings = () => {
      if (!isLongTermMobileDesign) {
        return;
      }
      const changedWeightingsCount = agronomicWeightings.filter((lw) => lw.value > 0).length;
      const agWeightingInputs: { [key: string]: any } = {
        'ag weightings applied': changedWeightingsCount,
      };
      for (const attribute of attributes) {
        const attributeInput = agronomicWeightings.find((lw) => attribute.id === lw.id);
        const hasAttributeValue = attributeInput ? attributeInput.value > 0 : false;
        agWeightingInputs[attribute.attributeName] = hasAttributeValue;
      }

      track('ag weightings', { ...agWeightingInputs });
    };
    trackSunflowerInputs();
    trackAgronomicWeightings();
  };

  const handleCancelClick = useCallback(() => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      onCancelClick();
    }
  }, [isMobile, onCancelClick]);

  useEffect(() => {
    if (isModalToBeClosed) {
      handleCancelClick();
      setIsModalToBeClosed({ isModalToBeClosed: false });
    }
  }, [handleCancelClick, isModalToBeClosed, setIsModalToBeClosed]);

  const handleAnimationEnd = () => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    onCancelClick();
  };

  const handleTermsClick = useCallback(() => {
    setIsTermsChecked((prev) => !prev);
  }, []);

  const handleUpdatesClick = useCallback(() => {
    setAgreeReceiveNewsUpdates((prev) => !prev);
  }, []);

  const handleSmsRecommendation = () => {
    setAgreeReceiveRecommendationOnSMS((prev) => !prev);
  };

  const isSmsRecommendationCheckboxDisabled = useCallback(() => {
    return Boolean(
      countryCode === TARGET_COUNTRIES_CODES.POL &&
        (hasInputErrors.phoneNumber ||
          phoneNumber?.length === 0 ||
          !phoneNumber ||
          phoneNumber?.length <= 4)
    );
  }, [hasInputErrors.phoneNumber, phoneNumber, countryCode]);

  const retrieveUserContactData = useCallback(() => {
    const sfdcIdUserContact = sfdcId || window.sessionStorage.getItem('sfdcId') || undefined;
    const userPhoneNumber = phoneNumber && phoneNumber?.length < 7 ? '' : phoneNumber;

    const userContact = {
      firstName: name,
      lastName: lastName,
      phoneNumber: userPhoneNumber,
      email: email,
      timezoneOffset: new Date().getTimezoneOffset(),
      receiveNewsUpdates: agreeReceiveNewsUpdates,
      sfdcId: sfdcIdUserContact,
      smsOptin: agreeReceiveRecommendationOnSMS,
    };
    return userContact;
  }, [
    agreeReceiveNewsUpdates,
    email,
    lastName,
    name,
    phoneNumber,
    sfdcId,
    agreeReceiveRecommendationOnSMS,
  ]);

  const handleGetRecommendationClick = useCallback(async () => {
    const otherAgInput = { selectedCrop, tppData, downyMildew };
    if (!fields?.length) {
      return;
    }
    const currentCountry = window.sessionStorage.getItem('currentCountry') ?? '';
    let recommendationInput = retrieveRecommendationInputData(
      agronomicInputs,
      otherAgInput,
      i18n,
      countryCode,
      countryRegion,
      agronomicWeightings,
      rankingCrop,
      t,
      countryList,
      attributes,
      fieldsAttributes
    );

    recommendationInput = { ...recommendationInput, currentCountry: currentCountry };
    const userContact = skipContactDetailsForm ? storedUserContact : retrieveUserContactData();
    setShowPrompt({ show: false });
    setEmailUpdatedList({ list: [email] });
    if (!storedUserContact) {
      setStoredUserContact({ storedUserContact: userContact });
    }

    // Track the time between when the user clicks on “Get recommendation“ and when they get an e-mail (or open the recommendation)
    // Amplitude set user id
    setUserId(email);

    let body = {};
    if ([OptionType.Draw, OptionType.Detect].includes(optionType)) {
      const boundaries = retrieveBoundariesData(fields);
      body = {
        recommendationInput,
        userContact,
        boundaries,
      };
      track('journey', { 'journey boundary completed': true });
    } else {
      const pins = retrievePinsData(fields, pin);
      body = {
        recommendationInput: recommendationInput,
        userContact: userContact,
        pins,
      };
      track('journey', { 'journey pin completed': true });
    }

    const utmParams = sessionStorage.getItem('utmParams');

    if (utmParams) {
      body = {
        ...body,
        utm: JSON.parse(utmParams),
      };
    }

    let status = 'in-progress';
    try {
      track('receive news', { 'receive news accepted': agreeReceiveNewsUpdates });
      if (agreeReceiveRecommendationOnSMS) {
        track('sms optin', { 'user sms optin': agreeReceiveRecommendationOnSMS });
        track('page user opting for sms', { 'user optin for sms page': 'get recommendation' });
      }
      setShowSpinner({ show: true });
      submittingRecommendation.current = true;

      const isAvailableOnUAT = await ProxyLayer.isSyngentaUser(email);
      track('user classification', {
        'user category':
          email.includes('@syngenta.co') || isAvailableOnUAT.status
            ? UserCategories.SYNGENTA_USER
            : UserCategories.NON_SYNGENTA_USER,
      });
      trackAmplitudeEvents(body);
      setEmailRecommendationSent({ sent: true });
      setJourneyCompleted({ completed: true });
      setShowUpdateInfoMessage({ show: false });
      const res = await ProxyLayer.postRecommendationInput(body);
      setRecommendationId({ recommendationId: res.id });
      setShowSpinner({ show: false });

      status = res.status;
    } catch (error) {
      // Ignore this error
      setEmailRecommendationSent({ sent: false });
    } finally {
      setShowSpinner({ show: false });
      sendConversionEventToGA(); // send conversion details to google analytics
      if (window.sessionStorage.getItem('sfdcId')) {
        window.sessionStorage.removeItem('sfdcId');
      }
      sessionStorage.removeItem('editingStepTwo');
      sessionStorage.removeItem('isCreatingAnotherRecommendation');
      onGetRecommendationClick(status);
    }
  }, [
    selectedCrop,
    tppData,
    downyMildew,
    fields,
    agronomicInputs,
    i18n,
    countryCode,
    agronomicWeightings,
    rankingCrop,
    skipContactDetailsForm,
    storedUserContact,
    retrieveUserContactData,
    setShowPrompt,
    setEmailUpdatedList,
    email,
    optionType,
    setStoredUserContact,
    pin,
    agreeReceiveNewsUpdates,
    setShowSpinner,
    setEmailRecommendationSent,
    setJourneyCompleted,
    setRecommendationId,
    onGetRecommendationClick,
    agronomicInputs,
    agronomicWeightings,
  ]);

  const disableGetRecommendationButton = useMemo(() => {
    const commonConditions =
      hasInputErrors.name ||
      hasInputErrors.email ||
      hasInputErrors.phoneNumber ||
      hasInputErrors.lastName ||
      !name ||
      !email ||
      !isTermsChecked ||
      !lastName;

    if (countryCode === TARGET_COUNTRIES_CODES.POL) {
      return commonConditions;
    } else {
      return commonConditions || !phoneNumber;
    }
  }, [hasInputErrors, name, email, isTermsChecked, lastName, phoneNumber, countryCode]);

  useEffect(() => {
    const isBrowserBackBtnClicked = !sessionStorage.getItem('isCreatingAnotherRecommendation');
    if (submittingRecommendation.current || !skipContactDetailsForm || isBrowserBackBtnClicked) {
      return;
    }
    track('finish create another recommendation on same field', {
      'user finish creating another recommendation': true,
    });

    handleGetRecommendationClick();
  }, [handleGetRecommendationClick, skipContactDetailsForm]);

  useEffect(() => {
    if (!agreeReceiveRecommendationOnSMS) {
      return;
    }
    if (
      countryCode === TARGET_COUNTRIES_CODES.POL &&
      hasInputErrors.phoneNumber &&
      agreeReceiveRecommendationOnSMS
    ) {
      setAgreeReceiveRecommendationOnSMS((prev) => !prev);
    }
  }, [phoneNumber, hasInputErrors.phoneNumber]);

  if (skipContactDetailsForm) {
    return <></>;
  }

  return (
    <StyledModalView
      data-testid="get-recommendation-modal"
      title={title ?? 'Title goes here'}
      width={494}
      isViewFooter
      showHeaderRectangle
      onCancel={handleCancelClick}
      isDropAPinFlow={isDropAPinFlow}
      content={
        <StyledMainContainer>
          <StyledParagraph>{description}</StyledParagraph>
          <InputTextHandler
            maxLength={20}
            inputMode="text"
            showRequiredIndicator
            label={`${t('First name')}`}
            placeholder={t('First name')}
            requiredFailMessage={t('First name is required')}
            inputName="name"
            inputValue={name}
            setInputValue={setName}
            isRequired
            type={InputTextHandlerType.NAME}
            setHasErrorsForParent={setHasInputErrors}
          />
          <InputTextHandler
            maxLength={20}
            showRequiredIndicator
            inputMode="text"
            label={`${t('Last name')}`}
            placeholder={t('Last name')}
            requiredFailMessage={t('Last name is required')}
            inputName="lastName"
            inputValue={lastName}
            setInputValue={setLastName}
            isRequired
            type={InputTextHandlerType.NAME}
            setHasErrorsForParent={setHasInputErrors}
          />
          <PhoneNumberWrapper
            label={t('Phone number')}
            isRequired={countryCode !== TARGET_COUNTRIES_CODES.POL}
            inputName="phoneNumber"
            requiredFailMessage={t('Phone number is required')}
            regexFailMessage={t(
              'Phone number cannot contain more than 15 digits or less than 7 digits'
            )}
            inputValue={phoneNumber}
            setInputValue={setPhoneNumber}
            setHasErrorsForParent={setHasInputErrors}
            country={postalCode}
          />
          <InputTextHandler
            showRequiredIndicator
            inputMode="email"
            label={`${t('Email Address')}`}
            placeholder={t('Email Address')}
            inputName="email"
            requiredFailMessage={t('Email address is required')}
            invalidMailMessage={t('Email address format is invalid.')}
            inputValue={email}
            setInputValue={setEmail}
            isRequired
            type={InputTextHandlerType.EMAIL}
            setHasErrorsForParent={setHasInputErrors}
          />
          {isSmsEnabledCountry && (
            <StyledCheckboxContainer>
              <Checkbox
                data-testid="agree-sms-recommendation-checkbox"
                checked={agreeReceiveRecommendationOnSMS}
                onClick={handleSmsRecommendation}
                disabled={isSmsRecommendationCheckboxDisabled()}
              >
                <StyledSpanText>{`${t('Also send recommendation via SMS')}`}</StyledSpanText>
              </Checkbox>
            </StyledCheckboxContainer>
          )}
          <StyledCheckboxContainer>
            <Checkbox
              data-testid="agree-terms-checkbox"
              checked={isTermsChecked}
              onClick={handleTermsClick}
            >
              <StyledSpanText>{`${t('I agree to Syngenta')} `}</StyledSpanText>
              <StyledAnchor
                href={getUrl(countryName)?.termsConditionURL}
                target="_blank"
                rel="noreferrer"
              >{`${t('Terms & Conditions')}`}</StyledAnchor>
              <StyledSpanText>{` ${t('and')} `}</StyledSpanText>
              <StyledAnchor
                href={getUrl(countryName)?.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >{`${t('Privacy Policy')}`}</StyledAnchor>
            </Checkbox>
          </StyledCheckboxContainer>
          <div>
            <Checkbox
              data-testid="agree-news-checkbox"
              checked={agreeReceiveNewsUpdates}
              onClick={handleUpdatesClick}
            >
              <StyledSpanText>{`${t(
                'I agree to receive Syngenta news and updates'
              )}`}</StyledSpanText>
            </Checkbox>
          </div>
        </StyledMainContainer>
      }
      footer={
        <StyledButtonRow gutter={16}>
          <ColButtonStyled span={24}>
            <StyledButton
              textSize="MEDIUM"
              buttonTestId="get-recommendation-button"
              type={EButtonType.primary}
              isDisabled={disableGetRecommendationButton}
              onClick={() => {
                handleGetRecommendationClick();
              }}
              text={t('Get Recommendation')}
            />
          </ColButtonStyled>
          <Col span={24}>
            <StyledButton
              textSize="MEDIUM"
              buttonTestId="pin-information-modal-cancel-button"
              type={EButtonType.default}
              onClick={handleCancelClick}
              text={t('Cancel')}
            />
          </Col>
        </StyledButtonRow>
      }
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default GetRecommendationModal;
